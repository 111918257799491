<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center">
        <div class="text-h5 font-weight-medium">
          Usuários
        </div>
        <HelpDialog
            class="mx-4"
            title="Usuários"
            message="Preciso de ajuda gerenciar meus usuários."
        >
          <p>
            <b>Adicione novos usuários</b> em sua conta para solicitar consentimentos clicando no botão <b>Novo Usuário</b>.
          </p>
          <p>
            <b>Gerencie os usuários</b> com acesso a sua conta clicando nos ícones ao lado dos dados do usuário:
            <ul class="mt-2">
              <li>
                <b>Edite as permissões</b> de seus usuários clicando no lápis.
              </li>
              <li>
                <b>Remova o acesso</b> de um usuário a sua conta clicando na lixeira.
              </li>
            </ul>
          </p>
        </HelpDialog>
        <!-- <v-sheet class="ml-4 text-caption" max-width="520px" style="word-break: normal">
        </v-sheet> -->
        <v-spacer />
        <v-btn color="success" @click="openUserDialog()">Novo Usuário</v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="accountUsers"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhum Usuário cadastrado"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top :open-delay="500">
            Editar Usuário
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="openUserDialog(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip top :open-delay="500">
            Deletar Usuário
            <template #activator="{ on }">
              <v-btn
                icon v-on="on"
                @click="deleteDialog = true; user_id = item.user_id;"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>

    <v-dialog
      v-model="userDialog"
      max-width="800"
      :persistent="loadingSave"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="userForm.user_id">Edição de Usuário</div>
          <div v-else>Novo Usuário</div>
          <div>
            <v-btn icon @click="userDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          O usuário já deve estar previamente cadastrado no eConsenso pelo aplicativo no celular.
          <v-form ref="form" @submit.prevent="saveUser()">
            <v-text-field
              v-model="userForm.email"
              label="E-Mail *"
              :readonly="userForm.user_id != undefined"
              outlined
              hide-details="auto"
              color="black"
              class="my-3 mr-1"
              :rules="[$rules.required, $rules.email]"
              :disabled="loadingSave"
            />
            <v-checkbox
              v-if="$store.state.user.have_accounts"
              v-model="userForm.allow_accounts_access"
              label="Permitir acesso a contas compartilhadas de terceiros"
              hide-details="auto"
            />
            <v-card-actions class="pt-3 pb-0 pr-0">
              <v-spacer />
              <v-btn
                color="secondary"
                class="mr-1"
                @click="userDialog = false"
                :disabled="loadingSave"
                text
              >
                Cancelar
              </v-btn>
              <v-btn color="success" type="submit" :loading="loadingSave">
                <template v-if="userForm.user_id"> Salvar </template>
                <template v-else> Criar </template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card>
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Usuário?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary" class="mr-1" text
            :disabled="loadingDelete" @click="deleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ml-1" :loading="loadingDelete"
            color="error" text
            @click="deleteUser()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue"

export default {
  name: "Users",

  components: { HelpDialog },

  data: () => ({
    search: "",
    loading: false,
    loadingDelete: false,
    userDialog: false,
    deleteDialog: false,
    loadingSave: false,
    userForm: {
      email: "",
      is_admin: false,
      allow_accounts_access: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "name",
      },
      { text: "E-Mail", value: "email" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    accountUsers: [],
  }),
  mounted() {
    this.getUsers();
  },
  methods: {
    saveUser() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if (this.userForm.user_id) {
          request = this.$axios.put(
            `/account-user`,
            this.userForm
          );
        } else {
          request = this.$axios.post("/account-user", this.userForm);
        }
        request
          .then((response) => {
            this.$showMessage("success", "Usuário Salvo");
            this.getUsers();
            this.userDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                this.$showMessage('warning', "Usuário não encontrado, solicite que cadastre-se no <b>app</b> eConsenso");
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    deleteUser() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/account-user/${this.user_id}`)
        .then((response) => {
          this.$showMessage("success", "Usuário Excluido de sua conta");
          this.getUsers();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Usuário não encontrado");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getUsers() {
      this.loading = true;
      this.$axios
        .get(`/account-users`)
        .then((response) => {
          this.accountUsers = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.$showMessage(
                "error",
                "Não foi possível carregar seus usuários"
              );
              return;
            }
          }
          this.$showMessage(
            "error",
            "Erro inesperado no carregamento dos usuários"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openUserDialog(user) {
      if (user) {
        this.userForm = Object.assign({}, user);
      } else {
        this.userForm = {
          email: "",
          is_admin: false,
          allow_accounts_access: false,
        };
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
      this.userDialog = true;
    },
  },
};
</script>